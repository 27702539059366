import { FC, useEffect } from 'react'
import moment from 'moment-timezone'
import {
  AppAnalytics,
  ShadowUserBanner,
  AppConfigProvider,
  QAInfoPopover,
  useIsThirdGen,
} from '@signifyd/components'
import { caseSearchTokens } from '@signifyd/sig-keys'
import { legacyTheme, thirdGenTheme } from '@signifyd/ant'
import useGetUserData from 'core/hooks/useGetUserData'
import AppRoutes from './AppRoutes'

const AppContainer: FC = () => {
  const userData = useGetUserData()

  const { currentUser, userConfigs } = userData

  const userTimeZone = currentUser?.uiState?.timeZone?.name

  useEffect(() => {
    if (userTimeZone) {
      moment.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  const isThirdGen = useIsThirdGen()

  const themeClassName = isThirdGen ? 'thirdGen' : 'secondGen'

  // Tooltips, popovers, and similar elements in ant are nested under a separate top level section under the body, this targets those too
  useEffect(() => {
    document.body.classList.add(themeClassName)

    return () => {
      document.body.classList.remove('thirdGen', 'secondGen')
    }
  }, [themeClassName])

  return (
    <div>
      <AppConfigProvider
        theme={isThirdGen ? thirdGenTheme : legacyTheme}
        localization={{
          appName: 'case-search',
        }}
        user={currentUser}
        userConfigs={userConfigs}
      >
        <AppAnalytics
          enabled={process.env.BUILD_ENV === 'production'}
          user={currentUser}
          logRocketId={caseSearchTokens.logRocketId}
          heapAppId={caseSearchTokens.heapAppId}
        />
        <AppRoutes userData={userData} />
        <ShadowUserBanner currentUser={currentUser} />
        <QAInfoPopover />
      </AppConfigProvider>
    </div>
  )
}

export default AppContainer
