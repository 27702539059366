import {
  FeatureFlagController,
  PageSpinnerThirdGen,
  thirdGenTreatmentKey,
  useUserContext,
} from '@signifyd/components'
import { FC } from 'react'

const featureFlagConfig = [{ key: thirdGenTreatmentKey, label: 'Third Gen' }]

const CaseSearchFeatureFlagController: FC = ({ children }) => {
  const { user } = useUserContext()

  return (
    <FeatureFlagController
      user={user}
      LoadingComponent={<PageSpinnerThirdGen />}
      featureFlagConfig={featureFlagConfig}
    >
      {children}
    </FeatureFlagController>
  )
}

export default CaseSearchFeatureFlagController
